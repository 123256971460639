

















































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
    apiDistributionDetails,
    apiDistributionSet
} from '@/api/distribution/distribution'

@Component({
    components: {}
})
export default class DistributionResultSet extends Vue {
    /** S Data **/

    list: any = {}

    /** E Data **/

    /** S Methods **/

    onSubmit() {
        apiDistributionSet({ ...this.list })
            .then(() => {
                this.detail()
                this.$message.success('修改成功!')
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    // 详情
    detail() {
        apiDistributionDetails({})
            .then(res => {
                this.list = res
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.detail()
    }
    /** E Life Cycle **/
}
